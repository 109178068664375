import styled from 'styled-components';
import { GlobalStyle } from 'assets/styles/styled/GlobalStyle';
import useRender from 'hooks/useRender';

function App() {
  const onChangeRenderMap = useRender();

  return (
    <AppArea>
      <GlobalStyle />
      {onChangeRenderMap()}
    </AppArea>
  );
}

const AppArea = styled.div`
  * {
    &:focus {
      outline: none;
    }
  }
`;

export default App;

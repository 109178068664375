import React from 'react';
import styled from 'styled-components';
import c from 'assets/images/bro/company/c.png';

export default function Company(props) {
  const { companyRef, tabIndex } = props;
  return (
    <Area ref={companyRef} tabIndex={tabIndex}>
      <ImgWrapper>
        <img src={c} alt="NO_IMAGE" />
      </ImgWrapper>
    </Area>
  );
}

const Area = styled.div``;
const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
  }
`;

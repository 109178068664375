import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Home from './home/home';

export default function Browser() {
  return (
    <Area>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Area>
  );
}
const Area = styled.div`
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
`;

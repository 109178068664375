import React from 'react';
import styled from 'styled-components';
import hLogo from 'assets/images/bro/header/hLogo.png';

export default function Header({ onFocus }) {
  return (
    <Area>
      <LogoImgWrapper>
        <img src={hLogo} alt="NO_IMAGE" />
      </LogoImgWrapper>
      <NavWrapper>
        <div onClick={() => onFocus(0)}>COMPANY</div>
        <div onClick={() => onFocus(1)}>BRAND</div>
        <div onClick={() => onFocus(2)}>SERVICE</div>
        <div onClick={() => onFocus(3)}>MAP</div>
        <div onClick={() => onFocus(4)}>R&D</div>
      </NavWrapper>
    </Area>
  );
}

const Area = styled.header`
  max-width: 1440px;
  width: 1440px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 12em 1em 12em;
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  gap: 45px;

  div {
    color: #0f325b;
    font-weight: 600;
    font-size: 1.25rem;
  }

  div:nth-of-type(1) {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  div:nth-of-type(2) {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  div:nth-of-type(3) {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  div:nth-of-type(4) {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  div:nth-of-type(5) {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

const LogoImgWrapper = styled.div`
  img {
    max-width: 100%;
  }
`;

import React from 'react';
import styled from 'styled-components';
import Brand from './brand/brand';
import Company from './company/company';
import Map from './map/map';
import Rnd from './rnd/rnd';
import Service from './service/service';

export default function Tap({ focusRef }) {
  //   const { focusRef } = useFocus();
  // const companyRef = focusRef[0];

  return (
    <Area>
      {/* {focusRef.map((ref, index) => (
        <div key={index} ref={ref} tabIndex={index}>
          {`Div ${index}`}
        </div>
      ))} */}
      {/* <Company ref={companyRef} tabIndex={0} /> */}
      {/* <Brand />
      <Service />
      <Map />
      <Rnd /> */}

      <Company companyRef={focusRef[0]} tabIndex={0} />
      <Brand brandRef={focusRef[1]} tabIndex={1} />
      <Service serviceRef={focusRef[2]} tabIndex={2} />
      <Map mapRef={focusRef[3]} tabIndex={3} />
      <Rnd rndRef={focusRef[4]} tabIndex={4} />
    </Area>
  );
}

const Area = styled.div``;

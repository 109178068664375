import React from 'react';
import s from 'assets/images/bro/ser/s.png';
import v2 from 'assets/video/v2.mp4';
import styled from 'styled-components';

export default function Service(props) {
  const { serviceRef, tabIndex } = props;

  return (
    <Area ref={serviceRef} tabIndex={tabIndex}>
      <video
        width="100%"
        height="100%"
        preload="auto"
        autoPlay
        playsInline
        muted="muted"
        poster="img.jpg"
        src={v2}
      />
      <div>
        <img src={s} alt="NO_IMAGE" />
      </div>
    </Area>
  );
}
const Area = styled.div`
  position: relative;
  video {
  }
  div {
    position: absolute;
    top: 0;
    height: 100%;
    img {
      max-width: 100%;
      height: 99.5%;
    }
  }
`;

import { isBrowser, isMobile } from "react-device-detect";

const { default: Browser } = require("browser");
const { default: Mobile } = require("mobile");

const renderMap = {
  broswer: <Browser />,
  mobile: <Mobile />,
  exception: <Mobile />,
};

export default function useRender() {
  const onChangeRenderMap = () => {
    if (isBrowser) return renderMap["broswer"];
    if (isMobile) return renderMap["mobile"];
    return renderMap["exception"];
  };

  return onChangeRenderMap;
}

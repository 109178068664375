import React from 'react';
import styled from 'styled-components';
import Footer from './footer';
import Header from './header';

export default function Layout({ children, onFocus }) {
  return (
    <Area>
      <Header onFocus={onFocus} />
      <Body>{children}</Body>
      <Footer />
    </Area>
  );
}

const Area = styled.section`
  width: 1440px;

  height: 100vh;
  margin-left: auto;
  margin-right: auto;
`;

const Body = styled.section`
  flex: 1;
`;

import Layout from 'mobile/components/layout';
import useFocus from 'hooks/useFocus';
import React from 'react';
import Tap from './tab/tap';
import mb from 'assets/images/mob/main/mb.png';
import mt from 'assets/images/mob/main/mt.png';
import v1 from 'assets/video/v1.mp4';
import styled from 'styled-components';
import pat from 'assets/images/mob/main/pat2.png';
import path from 'assets/images/mob/main/path.png';

export default function Home() {
  const { focusRef, onFocus } = useFocus();

  return (
    <Layout onFocus={onFocus}>
      <VideoWrapper>
        <video
          // width="100%"
          // height="100%"
          preload="auto"
          autoPlay
          playsInline
          muted="muted"
          poster="img.jpg"
          src={v1}
        />
        <div>
          <img src={mb} alt="NO_IMAGE" />
        </div>
        <div>
          <img src={mt} alt="NO_IMAGE" />
        </div>
      </VideoWrapper>
      <Tap focusRef={focusRef} onFocus={onFocus} />
      <B>
        <img src={path} alt="NO_IMAGE" />
        <img src={pat} alt="NO_IMAGE" />
      </B>
    </Layout>
  );
}

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 41vh;

  position: relative;
  overflow: hidden;

  video {
    width: 200%;
  }

  div:nth-of-type(1) {
    position: absolute;
    img {
      max-width: 100%;
      height: 100%;
    }
  }
  div:nth-of-type(2) {
    position: absolute;
    left: 1.7%;
    bottom: 15%;
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      max-width: 100%;
      width: 60%;
    }
  }
`;

const B = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 2.5em;
  position: relative;

  background-color: rgba(200, 200, 200, 0.1);
  img {
    width: 100%;
  }

  img:nth-of-type(1) {
  }
  img:nth-of-type(2) {
    position: absolute;
    bottom: 40px;
  }
`;

import React from 'react';
import ser from 'assets/images/mob/serv/ser.png';
import styled from 'styled-components';
import v2 from 'assets/video/v2.mp4';

export default function Service(props) {
  const { serviceRef, tabIndex } = props;

  return (
    <Area ref={serviceRef} tabIndex={tabIndex}>
      <VideoWrapper>
        <video
          // width="100%"
          // height="100%"
          preload="auto"
          autoPlay
          playsInline
          muted="muted"
          poster="img.jpg"
          src={v2}
        />
        <p>
          <span>03&nbsp;</span> <span>SERVICE</span>
        </p>
      </VideoWrapper>
      <div>
        <img src={ser} alt="NO_IMAGE" />
      </div>
    </Area>
  );
}

const Area = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  div:nth-of-type(2) {
    width: 100%;
    margin-top: -1em;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
`;
const VideoWrapper = styled.div`
  position: relative;
  video {
    max-width: 100%;
    width: 100%;
  }

  p {
    position: absolute;
    top: 45%;
    left: 10%;

    span:nth-of-type(1) {
      color: #c5c5c5;
      font-weight: 900;
      font-size: 3.2rem;
      letter-spacing: -0.05em;
    }
    span:nth-of-type(2) {
      font-weight: 700;
      color: #ffffff;
      font-size: 2.7rem;
      letter-spacing: -0.05em;
    }
  }
`;

import React from 'react';
import br from 'assets/images/bro/brand/br.png';
import br1 from 'assets/images/bro/brand/br1.png';
import br2 from 'assets/images/bro/brand/br2.png';
import br3 from 'assets/images/bro/brand/br3.png';
import br4 from 'assets/images/bro/brand/br4.png';
import styled from 'styled-components';

export default function Brand(props) {
  const { brandRef, tabIndex } = props;

  return (
    <div ref={brandRef} tabIndex={tabIndex}>
      <ImgWrapper>
        <img src={br} alt="NO_IMAGE" />
        <a
          href={'https://www.secureapp.co.kr/'}
          target="_blank"
          rel="noreferrer"
        >
          <img src={br1} alt="NO_IMAGE" />
        </a>
        <a href={'https://www.bohome.co.kr/'} target="_blank" rel="noreferrer">
          <img src={br2} alt="NO_IMAGE" />
        </a>
        <a
          href={'https://www.apkcapture.org/'}
          target="_blank"
          rel="noreferrer"
        >
          <img src={br3} alt="NO_IMAGE" />
        </a>
        <a href={'https://www.let-sweb.com/'} target="_blank" rel="noreferrer">
          <img src={br4} alt="NO_IMAGE" />
        </a>
      </ImgWrapper>
    </div>
  );
}

const ImgWrapper = styled.div`
  position: relative;
  img {
    max-width: 100%;
  }

  a:nth-of-type(1) {
    position: absolute;
    top: 45%;
    left: 19.9%;
    width: 15%;
    border-radius: 20px;

    &:hover {
      background-image: linear-gradient(to top, #d9d9d9 29.6%, #526d9a 80%);
      height: fit-content;
      max-height: 31.5%;
    }
  }
  a:nth-of-type(2) {
    position: absolute;
    top: 45%;
    left: 35.5%;
    width: 15%;
    border-radius: 20px;

    &:hover {
      background-image: linear-gradient(to top, #d9d9d9 29.6%, #526d9a 80%);
      height: fit-content;
      max-height: 31.5%;
    }
  }
  a:nth-of-type(3) {
    position: absolute;
    top: 45%;
    left: 51.05%;
    width: 15%;
    border-radius: 20px;

    &:hover {
      background-image: linear-gradient(to top, #d9d9d9 29.6%, #526d9a 80%);
      height: fit-content;
      max-height: 31.5%;
    }
  }
  a:nth-of-type(4) {
    position: absolute;
    top: 45%;
    left: 66.7%;
    width: 15%;
    border-radius: 20px;

    &:hover {
      background-image: linear-gradient(to top, #d9d9d9 29.6%, #526d9a 80%);
      height: fit-content;
      max-height: 31.5%;
    }
  }
`;

import React from 'react';
import mc from 'assets/images/mob/company/mc.png';
import styled from 'styled-components';

export default function Company(props) {
  const { companyRef, tabIndex } = props;
  return (
    <Area ref={companyRef} tabIndex={tabIndex}>
      <div>
        <img src={mc} alt="NO_IMAGE" />
      </div>
    </Area>
  );
}

const Area = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
`;

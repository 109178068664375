import React from 'react';
import mr1 from 'assets/images/mob/rnd/mr1.png';
import mr2 from 'assets/images/mob/rnd/mr2.png';
import mr3 from 'assets/images/mob/rnd/mr3.png';
import mr4 from 'assets/images/mob/rnd/mr4.png';
import v3 from 'assets/video/v3.mp4';
import styled from 'styled-components';

export default function Rnd(props) {
  const { rndRef, tabIndex } = props;
  return (
    <Area ref={rndRef} tabIndex={tabIndex}>
      <video
        // width="100%"
        // height="100%"
        preload="auto"
        autoPlay
        playsInline
        muted="muted"
        poster="img.jpg"
        src={v3}
      />
      <div>
        <img src={mr1} alt="NO_IMAGE" />
      </div>
      <div>
        <img src={mr2} alt="NO_IMAGE" />
      </div>
      <div>
        <img src={mr3} alt="NO_IMAGE" />
      </div>
      <div>
        <img src={mr4} alt="NO_IMAGE" />
      </div>
    </Area>
  );
}

const Area = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 190%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  video {
    width: 190%;
    height: 190%;
  }

  div {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    img {
    }
  }
  div:nth-of-type(1) {
    top: 10%;
    left: -20%;
    img {
      width: 60%;
    }
  }
  div:nth-of-type(2) {
    top: 35%;
    left: 0%;
    img {
      width: 88%;
    }
  }
  div:nth-of-type(3) {
    top: 55%;
    left: 0%;
    img {
      width: 70%;
    }
  }
  div:nth-of-type(4) {
    top: 70%;
    left: 0%;
    img {
      width: 40%;
    }
  }
`;

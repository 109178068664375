import React from 'react';
import mf from 'assets/images/mob/footer/mf.png';
import styled from 'styled-components';

export default function Footer() {
  return (
    <Area>
      <div>
        <img src={mf} alt="NO_IMAGE" />
        <a href="#3">{}</a>
        <a href="#3">{}</a>
      </div>
    </Area>
  );
}

const Area = styled.footer`
  width: 100%;

  div {
    width: 100%;
    display: flex;
    justify-content: center;

    position: relative;
    img {
      width: 100%;
    }

    a {
      position: absolute;
    }
    a:nth-of-type(1) {
      width: 22.6%;
      height: 10%;
      top: 4.2%;
      left: 37%;
      z-index: 1;
    }
    a:nth-of-type(2) {
      width: 22.6%;
      height: 10%;
      top: 4.2%;
      left: 62%;
      z-index: 1;
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import hLogo from 'assets/images/bro/header/hLogo.png';
import hb from 'assets/images/bro/header/hb.png';

export default function Header({ setNavStatus }) {
  return (
    <Area>
      <LogoImgWrapper>
        <img src={hLogo} alt="NO_IMAGE" />
      </LogoImgWrapper>
      <div onClick={() => setNavStatus((prev) => !prev)}>
        <img src={hb} alt="NO_IMAGE" />
      </div>
    </Area>
  );
}

const Area = styled.header`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1.6em;
`;
const LogoImgWrapper = styled.div`
  img {
    max-width: 100%;
  }
`;

import React from 'react';
import mm from 'assets/images/mob/map/mm.png';
import styled from 'styled-components';

export default function Map(props) {
  const { mapRef, tabIndex } = props;
  return (
    <Area ref={mapRef} tabIndex={tabIndex}>
      <div>
        <img src={mm} alt="NO_IMAGE" />
        <a
          href="https://map.naver.com/v5/entry/place/1953288779?placePath=%2Fhome%3Fentry=plt&c=15,0,0,0,dh"
          target="_blank"
          rel="noreferrer"
        >
          {}
        </a>
      </div>
    </Area>
  );
}

const Area = styled.div`
  margin-top: -0.2em;
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      width: 100%;
    }

    a {
      position: absolute;
      width: 70%;
      height: 8%;
      background-color: rgba(0, 0, 0, 0);
      bottom: 5%;
      z-index: 1;
    }
  }
`;

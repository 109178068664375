import { useRef } from 'react';

export default function useFocus() {
  const focusRef = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const onFocus = (index) => {
    focusRef[index].current.focus();
  };

  return { focusRef, onFocus };
}

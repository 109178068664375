import React from 'react';
import fi from 'assets/images/bro/footer/footer.png';
import styled from 'styled-components';

export default function Footer() {
  return (
    <Area>
      <div>
        <img src={fi} alt="NO_IMAGE" />
      </div>
      <a href="#3">{}</a>
      <a href="#3">{}</a>
    </Area>
  );
}

const Area = styled.div`
  background-color: #434343;
  position: relative;
  div {
    img {
      max-width: 100%;
    }
  }

  a:nth-of-type(1) {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    top: 34%;
    right: 19.5%;
    width: 130px;
    height: 40px;

    z-index: 1;

    &:hover {
    }
  }
  a:nth-of-type(2) {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    top: 54%;
    right: 19.5%;
    width: 130px;
    height: 40px;

    z-index: 1;
  }
`;

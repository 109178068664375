import React from 'react';
import mbr from 'assets/images/mob/brand/mbr2.png';
import styled from 'styled-components';

export default function Brand(props) {
  const { brandRef, tabIndex } = props;

  return (
    <Area ref={brandRef} tabIndex={tabIndex}>
      <div>
        <img src={mbr} alt="NO_IMAGE" />
      </div>
      <a href={'https://www.secureapp.co.kr/'} target="_blank" rel="noreferrer">
        {}
      </a>
      <a href={'https://www.bohome.co.kr/'} target="_blank" rel="noreferrer">
        {}
      </a>
      <a href={'https://www.apkcapture.org/'} target="_blank" rel="noreferrer">
        {}
      </a>
      <a href={'https://www.let-sweb.com/'} target="_blank" rel="noreferrer">
        {}
      </a>
    </Area>
  );
}

const Area = styled.div`
  margin-top: -1em;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      width: 100%;
    }
  }
  a {
    position: absolute;
    width: 82.5%;
    height: 17%;
    left: 9%;
    z-index: 1;
  }
  a:nth-of-type(1) {
    top: 19.3%;
  }
  a:nth-of-type(2) {
    top: 39%;
  }
  a:nth-of-type(3) {
    top: 59%;
  }
  a:nth-of-type(4) {
    top: 79%;
  }
`;

import Layout from 'browser/components/layout';
import useFocus from 'hooks/useFocus';
import React from 'react';
import styled from 'styled-components';
import Tap from './tab/tap';
import v1 from 'assets/video/v1.mp4';
import mainV from 'assets/images/bro/main/mainV.png';
import patent from 'assets/images/bro/main/patent.png';

export default function Home() {
  const { focusRef, onFocus } = useFocus();

  return (
    <Layout onFocus={onFocus}>
      <Area>
        <VideoWrapper>
          <div>
            <video
              // width="100%"
              // height="100%"
              preload="auto"
              autoPlay
              playsInline
              muted="muted"
              poster="img.jpg"
              src={v1}
            />
          </div>
          <ImgWrapper>
            <img src={mainV} alt="NO_IMAGE" />
          </ImgWrapper>
          <TextWrapper>
            <p>Ensuring the security of your i</p>
            <p>information, always</p>
          </TextWrapper>
        </VideoWrapper>
        <Tap focusRef={focusRef} onFocus={onFocus} />
        <BottomImgWrapper>
          <img src={patent} alt="NO_IMAGE" />
        </BottomImgWrapper>
      </Area>
    </Layout>
  );
}

const Area = styled.div`
  height: 100%;
  width: 1440px;
`;

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;

  div:nth-of-type(1) {
    height: 818.9px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
    }
  }
`;
const ImgWrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  img {
    max-width: 100%;
    height: 100%;
  }
`;
const TextWrapper = styled.div`
  position: absolute;
  top: 55%;
  left: 36%;

  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-weight: 350;
    font-size: 1.8rem;
    line-height: 2.4rem;

    text-align: center;
    letter-spacing: -0.02em;

    color: #ffffff;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const BottomImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.5em;
  margin-bottom: 5.5em;

  img {
    max-width: 100%;
  }
`;

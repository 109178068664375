import React from 'react';
import m from 'assets/images/bro/map/m.png';
import styled from 'styled-components';

export default function Map(props) {
  const { mapRef, tabIndex } = props;
  return (
    <Area ref={mapRef} tabIndex={tabIndex}>
      <div>
        <img src={m} alt="NO_IMAGE" />
      </div>
      <a
        href="https://map.naver.com/v5/entry/place/1953288779?placePath=%2Fhome%3Fentry=plt&c=15,0,0,0,dh"
        target="_blank"
        rel="noreferrer"
      >
        {}
      </a>
    </Area>
  );
}

const Area = styled.div`
  margin-top: -6.2em;
  margin-bottom: -6.045em;
  position: relative;

  div {
    img {
      max-width: 100%;
    }
  }

  a {
    position: absolute;
    width: 101px;
    height: 40px;
    z-index: 1;
    top: 22%;
    right: 19%;
    background-color: rgba(0, 0, 0, 0);
  }
`;

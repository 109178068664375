import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Home from './home/home';

export default function Mobile() {
  return (
    <Area>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Area>
  );
}

const Area = styled.div`
  width: 100%;
`;

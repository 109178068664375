import React from 'react';
import v3 from 'assets/video/v3.mp4';
import r1 from 'assets/images/bro/rnd/r1.png';
import r2 from 'assets/images/bro/rnd/r2.png';
import r3 from 'assets/images/bro/rnd/r3.png';
import r4 from 'assets/images/bro/rnd/r4.png';
import r5 from 'assets/images/bro/rnd/r5.png';
import styled from 'styled-components';

export default function Rnd(props) {
  const { rndRef, tabIndex } = props;
  return (
    <Area ref={rndRef} tabIndex={tabIndex}>
      <video
        width="100%"
        height="100%"
        preload="auto"
        autoPlay
        playsInline
        muted="muted"
        poster="img.jpg"
        src={v3}
      />
      <div>
        <img src={r1} alt="NO_IMAGE" />
      </div>
      <div>
        <img src={r2} alt="NO_IMAGE" />
      </div>
      <div>
        <img src={r3} alt="NO_IMAGE" />
      </div>
      <div>
        <img src={r4} alt="NO_IMAGE" />
      </div>
      <div>
        <img src={r5} alt="NO_IMAGE" />
      </div>
    </Area>
  );
}

const Area = styled.div`
  position: relative;

  div {
    position: absolute;
  }

  div:nth-of-type(1) {
    top: 8%;
    left: 18%;
  }
  div:nth-of-type(2) {
    top: 30%;
    left: 45%;
  }
  div:nth-of-type(3) {
    top: 36%;
    left: 22%;
  }
  div:nth-of-type(4) {
    top: 48%;
    left: 28%;
  }
  div:nth-of-type(5) {
    top: 64%;
    left: 38%;
  }
`;

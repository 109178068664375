import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Footer from './footer';
import Header from './header';
import Nav from './nav';

export default function Layout({ children, onFocus }) {
  const [navStatus, setNavStatus] = useState(false);
  return (
    <Area>
      <Nav
        onFocus={onFocus}
        navStatus={navStatus}
        setNavStatus={setNavStatus}
      />
      <Header setNavStatus={setNavStatus} />
      <Body>{children}</Body>
      <Footer />
    </Area>
  );
}

const Area = styled.div`
  /* width: 100%;
  height: 100vh; */
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  flex: 1;
`;

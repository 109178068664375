import React from 'react';
import styled from 'styled-components';

export default function Nav({ onFocus, navStatus, setNavStatus }) {
  const onF = (num) => {
    onFocus(num);
    setNavStatus(false);
  };
  return (
    <NavArea navStatus={navStatus}>
      <NavWrapper>
        <div onClick={() => onF(0)}>COMPANY</div>
        <div onClick={() => onF(1)}>BRAND</div>
        <div onClick={() => onF(2)}>SERVICE</div>
        <div onClick={() => onF(3)}>MAP</div>
        <div onClick={() => onF(4)}>R&D</div>
      </NavWrapper>
      <X onClick={() => setNavStatus(false)}>X</X>
    </NavArea>
  );
}

const NavArea = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.73);
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: 0;

  z-index: ${({ navStatus }) => (navStatus ? `2` : `-1`)};
  right: ${({ navStatus }) => (navStatus ? `0` : `-100vw`)};
  transition: right 0.6s ease;

  display: flex;
  flex-direction: row-reverse;
`;

const X = styled.div`
  color: #ffffff;
  font-size: 3rem;
  font-weight: 700;
  margin-top: 5.6em;
  margin-right: 0.2em;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  background-color: #ffffff;
  height: 19em;

  div {
    padding: 1em 6em 1em 1em;

    color: #35455f;
    font-weight: 600;
    font-size: 1.25rem;
  }

  div:nth-of-type(1) {
    border-bottom: 0.5px solid rgba(53, 69, 95, 0.5);
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  div:nth-of-type(2) {
    border-bottom: 0.5px solid rgba(53, 69, 95, 0.5);

    color: #35455f;
    font-weight: 600;
    font-size: 1.25rem;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  div:nth-of-type(3) {
    border-bottom: 0.5px solid rgba(53, 69, 95, 0.5);
    width: 100%;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  div:nth-of-type(4) {
    border-bottom: 0.5px solid rgba(53, 69, 95, 0.5);

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  div:nth-of-type(5) {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;
